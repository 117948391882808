<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {shebeilist} from '@/api/index.js';

export default {
    mounted() {
    this.checkScreenWidth();
    // 监听窗口大小变化
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeDestroy() {
    // 移除监听器
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      // return 
      if (window.innerWidth < 1100) {
                window.location.href = URLS.mobileurl; // 跳转到新的 HTTP 链接
                // console.log('xiaoyu900 :>> ');

      }
    }
  },
  created() {
     shebeilist().then(res=>{
      //console.log('res,设备 :>> ', res);
      res = res.map(item=>{
        item.act = 0
        return item
      })
      let y = JSON.stringify(res)
            localStorage.setItem('myshebei', y);
            
            this.$store.commit('setshebei',res)

    })
  }

}
</script>

<style lang="scss">
/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
 #app{
  display: flex;
  justify-items: center;
  min-height: calc(100vh + 20px);
    background-color: #dbedff;
    justify-content: center;

    background-image: url("./assets/wangge.png");
  background-repeat: repeat;
  background-size: contain;
 }
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: rgba(0,0,0,.2);
    }

    /*定义滚动条轨道
 内阴影+圆角*/
    ::-webkit-scrollbar-track
    {
        -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
        border-radius:10px;
        background-color:#CCE5FF;
    }
    /*定义滑块
     内阴影+圆角*/
    ::-webkit-scrollbar-thumb
    {
        border-radius:10px;
        -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
        background-color: #FFFFFF;
    }
</style>
