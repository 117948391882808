import axios from 'axios'
// import { Message } from 'element-ui'

const baseURL = URLS.baseURL;
// axios的实例
const service = axios.create({
  // 配置根路径
  baseURL: baseURL,
  // 请求超时时间
  timeout: 5000
})

// 添加请求拦截器,对token进行统一的请求配置
service.interceptors.request.use(function(config) {
  // 在发送请求之前做些什么
  // const token = store.getters.token
  // 判断有无token
  // if (token) {
    // config.headers['Authorization'] = 'Bearer ' + token
  // }
  return config
}, function(error) {
  return Promise.reject(error)
})

// 添加响应拦截器，统一对axios拿到的数据进行解构和对全局的错误进行统一弹框管理
service.interceptors.response.use(
  // 第一个函数触发：只要数据请求成功
  function(res) {
    // 在发送请求之前做些什么
    // 结构success
    // let { code, data, msg } = res
    // //console.log('xxxxxxxxxxxxx',res.data,res);
    return res.data.data

  },
)

// 导出axios实例对象
export default service
